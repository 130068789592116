import axios from "axios";
import React, { useState, useEffect } from "react";
import DepartmentList from "./DepartmentList";
import { useQuery } from "react-query";
import { Auth } from "aws-amplify";
import { departmentList as departmentListAtom } from "./Atoms.js";
import { useRecoilState } from "recoil";
import useUsers from "../Hooks/useUsers";
import EditUser from "./EditUser";
import { ReactComponent as Spinner } from "../images/Spinner.svg";

const Departments = () => {
	const [difference, setDifference] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [departmentList, setDepartmentList] = useRecoilState(
		departmentListAtom
	);

	async function getDepartments() {
		const user = await Auth.currentAuthenticatedUser();
		const idToken = user.signInUserSession.idToken.jwtToken;
		const data = await axios.get(`${process.env.REACT_APP_API}/departments`, {
			headers: {
				Authorization: idToken,
			},
		});
		console.log(data);
		let u = users.data.map((user) => {
			return user.Attributes[0].Value;
		});
		let d;
		if (data.data && departments.length === 0) {
			d = [
				...new Set(
					data.data.map((user) => {
						return user.email;
					})
				),
			];
			let diff = u.filter((x) => !d.includes(x));
			let extra = diff.map((e) => {
				return {
					department_name: "Unassigned",
					email: e,
				};
			});
			let deps = [...data.data, ...extra];
			console.log(deps);
			setDepartmentList(data);
			return deps;
		}
	}

	useEffect(() => {
		console.log(departmentList);
	}, [departmentList]);

	const users = useUsers();
	const dep = useQuery("departments", getDepartments, {
		enabled: users.length,
	});

	// useEffect(() => {

	// }, [dep])

	return (
		<div style={{ margin: "0 auto", width: "100%" }}>
			{dep.data ? (
				<>
					<EditUser departments={dep.data} />
					<DepartmentList departments={dep.data} />
				</>
			) : (
				<Spinner />
			)}
		</div>
	);
};

export default Departments;
