import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { ReactComponent as Icon } from "../images/Spinner.svg";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
	useMutation,
	QueryClient,
	useQueryClient,
	useQuery,
} from "react-query";
import { useRecoilState } from "recoil";
import {
	submitting as submittingAtom,
	addUserModal as addUserModalAtom,
	currentPool as currentPoolAtom,
	currentAccount as currentAccountAtom,
	auth as authAtom,
} from "./Atoms.js";
import axios from "axios";
import { toast } from "react-toastify";
import { Checkbox, Label } from "semantic-ui-react";
import useDepartments from "../Hooks/useDepartments";
import useDepartmentList from "../Hooks/useDepartmentList";

const AddUser = ({ brands }) => {
	const queryClient = useQueryClient();

	const [account, setAccount] = useRecoilState(currentAccountAtom);
	const [open, setOpen] = useRecoilState(addUserModalAtom);
	const [pool, setPool] = useRecoilState(currentPoolAtom);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [groups, setGroups] = useState([]);
	const [brand, setBrand] = useState("Polo");
	const [submitting, setSubmitting] = useRecoilState(submittingAtom);
	const [auth, setAuth] = useRecoilState(authAtom);

	const departments = useDepartments();

	const departmentList = useDepartmentList();
	const handleAddClose = () => {
		setOpen(false);
	};
	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	};
	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
	};

	function handleBrand(event, data) {
		setBrand(data.value);
	}

	const handleDepartment = (event, data) => {
		let departments = [...groups];
		if (departments.includes(data.value)) {
			let index = departments.indexOf(data.value);
			departments.splice(index, 1);
			setGroups(departments);
		} else {
			departments.push(data.value);
			setGroups(departments);
		}
	};

	function handleChecked(name) {
		if (name === brand) {
			return true;
		} else {
			return false;
		}
	}

	const notify = () =>
		toast(`${email} successfully added.`, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});

	const handleSubmit = async () => {
			setSubmitting(true);
			const body = {
				email: email,
				// departments: groups,
			};
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_API}/add`,
					body,
					{
						headers: {
							Authorization: auth,
						},
					}
				);
				console.log(response);
				if (response.status === 200) {
					console.log(response.data);
					let sqlBody = {
						// departments: groups,
						user_id: response.data,
						email: email,
						brand: brand,
					};
					console.log(sqlBody);
					const sqlResponse = await axios.post(
						`${process.env.REACT_APP_API}/addDB`,
						sqlBody,
						{
							headers: {
								Authorization: auth,
							},
						}
					);
					console.log(sqlResponse);
					if (sqlResponse.status === 200) {
						if (response.data.message === undefined) {
							toast.success(`${email} successfully added.`);
							// setGroups([]);
							// setEmail("");
							// setBrand("");
						} else {
							toast.error(response.data.message);
						}
					}
				}
			} catch (e) {
				if (e) {
					toast.error("Error");
				}
			}

			setOpen(false);
			setSubmitting(false);
		
	};

	const mutate = useMutation(handleSubmit, {
		onSuccess: () => {
			queryClient.invalidateQueries(pool);
		},
	});

	return (
		<div style={{ height: "60vh" }}>
			<Dialog
				open={open}
				onClose={handleAddClose}
				aria-labelledby="form-dialog-title"
				maxWidth="sm"
				fullWidth={true}
			>
				<DialogTitle id="form-dialog-title">Add User</DialogTitle>
				<DialogContent>
					<TextField
						onChange={handleEmailChange}
						value={email}
						autoFocus
						margin="dense"
						id="email"
						label="Email Address"
						type="email"
						fullWidth
					/>
					{/* <div
						style={{
							display: "flex",
							flexDirection: "column",
							height: "10vh",
							justifyContent: "space-evenly",
							marginTop: "10vh",
						}}
					>
						<Label pointing="below" style={{ marginTop: "10vh" }}>
							Select Department(s)
						</Label>
						{departmentList.status === "success" &&
							departmentList?.data.map((group, index) => {
								return (
									<Checkbox
										label={group.department_name}
										value={group.department_name}
										key={index}
										onChange={handleDepartment}
									/>
								);
							})}
						<Label pointing="below" style={{ marginTop: "2vh" }}>
							Select Brand
						</Label>
						{brands.data &&
							brands?.data?.map((b, index) => {
								return (
									<Checkbox
										label={b.name}
										value={b.name}
										key={index}
										onChange={handleBrand}
										checked={handleChecked(b.name)}
									/>
								);
							})}
					</div> */}
				</DialogContent>
				<DialogActions>
					{submitting ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								width: "50vw",
							}}
						>
							<Icon style={{ width: "80px" }} />
						</div>
					) : (
						<>
							<Button onClick={handleAddClose} color="primary">
								Cancel
							</Button>
							<Button onClick={mutate.mutate} color="primary">
								Confirm
							</Button>
						</>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default AddUser;
