import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { ReactComponent as Icon } from "../images/Spinner.svg";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useMutation, QueryClient, useQueryClient } from "react-query";
import { useRecoilState } from "recoil";
import {
	submitting as submittingAtom,
	editReportModal as editReportModalAtom,
	currentPool as currentPoolAtom,
	currentAccount as currentAccountAtom,
    auth as authAtom,
    selectedDepartmentEdit as selectedDepartmentEditAtom,
    departmentList as departmentListAtom,
    selectedReport as selectedReportAtom
} from "./Atoms.js";
import axios from "axios";
import { toast } from "react-toastify";
import { Checkbox, Label } from 'semantic-ui-react'
import { label } from "aws-amplify";

const EditReport = ({exclusions, departments}) => {
	const queryClient = useQueryClient();

    const [open, setOpen] = useRecoilState(editReportModalAtom);
    const [groups, setGroups] = useState([]);
	const [selectedDepartmentEdit, setSelectedDepartmentEdit] = useRecoilState(selectedDepartmentEditAtom);
    const [selectedReport, setSelectedReport] = useRecoilState(selectedReportAtom);
    const [submitting, setSubmitting] = useRecoilState(submittingAtom);
    const [auth, setAuth] = useRecoilState(authAtom);
    const [list, setList] = useState([]);
    const [included, setIncluded] = useState([]);
    const [excluded, setExcluded] = useState([]);

    useEffect(() => {
        if (departments) {
            let list = departments.filter(user => {
                return user.department_name === selectedDepartmentEdit;
            }).map(u => {
                return u.email
            });
            setList(list);
            console.log(list);
        }

	}, [selectedDepartmentEdit])
	
	useEffect(() => {
		let excl = exclusions?.map(e => {
			return e.email;
		})
		setExcluded(excl);
	}, [exclusions])


	const handleAddClose = () => {
		setOpen(false);
	};

	const handleDepartment = (event, data) => {
		let excl = [...excluded];
		if (excl.includes(data.value)) {
			let index = excl.indexOf(data.value);
			excl.splice(index,1);
			console.log(excl);
			setExcluded(excl);
		}

		else {
			excl.push(data.value);
			setExcluded(excl);
        }
      };
      

	const notify = () =>
		toast(` successfully added.`, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});

	const handleSubmit = async () => {
        // console.log(included);
        const body = {
            users: excluded,
            report: selectedReport
        }
        setSubmitting(true);
        const response = await axios.post(
			`${process.env.REACT_APP_API}/editreport`,
			body,
			{
				headers: {
					Authorization: auth,
				},
			}
		);
		console.log(response);
		setOpen(false);
		if (response.data.rowsAffected[0] > 0) {
			toast.success(`Success.`);
		} else {
			toast.error(response.data.message);
		}
        setSubmitting(false);
        return response;
	};

	const mutate = useMutation(handleSubmit, {
		onSuccess: () => {
            console.log("success")
			queryClient.invalidateQueries("departments");
		},
    });
    
    function handleChecked(user) {
        if (excluded.includes(user)) {
            return true;
        }
    }

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleAddClose}
				aria-labelledby="form-dialog-title"
				maxWidth="sm"
				fullWidth={true}
			>
				<DialogTitle id="form-dialog-title">Create Exclusion Rule</DialogTitle>
				<DialogContent>
                    <div style={{display: "flex", flexDirection: "column"}}>

                        {list.map((user, index) => {
                            return <Checkbox style={{marginTop: "10px"}} checked={handleChecked(user)} label={user} value={user} key={index} onChange={handleDepartment}/>
                        })} 
                    </div>
				

				</DialogContent>
				<DialogActions>
					{submitting ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								width: "50vw",
							}}
						>
							<Icon style={{ width: "80px" }} />
						</div>
					) : (
						<>
							<Button onClick={handleAddClose} color="primary">
								Cancel
							</Button>
							<Button onClick={mutate.mutate} color="primary">
								Save
							</Button>
						</>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default EditReport;
