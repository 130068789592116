import React, { useState, useEffect } from "react";
import MaterialTable, { MTableGroupbar, MTableToolbar } from "material-table";
import { useRecoilState } from "recoil";
import {
	editUserModal as editUserModalAtom,
	selectedUser as selectedUserAtom,
	selectedDepartmentEdit as selectedDepartmentEditAtom,
} from "./Atoms.js";
import { Button } from "semantic-ui-react";

const DepartmentList = ({ departments }) => {
	const [editUserModal, setEditUserModal] = useRecoilState(editUserModalAtom);
	const [selectedDepartmentEdit, setSelectedDepartmentEdit] = useRecoilState(
		selectedDepartmentEditAtom
	);
	const [grouping, setGrouping] = useState("Email");

	function handleSelect(rowData) {
		setSelectedDepartmentEdit(rowData.email);
		setEditUserModal(true);
	}

	function handleGrouping() {
		if (grouping === "Email") {
			setGrouping("Department");
			setColumns(departmentColumns);
		}

		if (grouping === "Department") {
			setGrouping("Email");
			setColumns(emailColumns);
		}
	}

	const emailColumns = [
		{
			title: "Email",
			field: "email",
			defaultGroupOrder: 0,
		},
		{
			title: "Department",
			field: "department_name",
		},
	];

	const departmentColumns = [
		{
			title: "Email",
			field: "email",
		},
		{
			title: "Department",
			field: "department_name",
			defaultGroupOrder: 0,
		},
	];

	const tableStyle = {
		// display: "flex",
		// flexDirection: "column",
		// justifyContent: "center",
		// alignItems: "center",
		// backgroundColor: "yellow"
	};

	const style = {
		textAlign: "left",
		fontFamily: '"Montserrat", sans-serif',
	};

	const [columns, setColumns] = useState(emailColumns);

	return (
		<div style={tableStyle}>
			<div>
				<MaterialTable
					title={`Departments`}
					columns={columns}
					data={departments}
					style={tableStyle}
					actions={[
						{
							icon: "edit",
							tooltip: "Edit",
							onClick: (event, rowData) => {
								handleSelect(rowData);
							},
						},
					]}
					components={{
						Groupbar: (props) => (
							<div style={{ height: "0px" }}>
								<MTableGroupbar style={{ display: "none" }} {...props} />
							</div>
						),
						Toolbar: (props) => (
							<div style={{ display: "flex", flexDirection: "column" }}>
								<MTableToolbar style={{ display: "none" }} {...props} />
								<Button
									onClick={handleGrouping}
									style={{ alignSelf: "center" }}
								>
									Grouping: {grouping}
								</Button>
							</div>
						),
					}}
					options={{
						toolbar: true,
						search: true,
						grouping: true,
						filtering: false,
						paging: false,
						actionsColumnIndex: 8,
						headerStyle: {
							textAlign: "left",
						},
					}}
				/>
			</div>
		</div>
	);
};

export default DepartmentList;
