import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { ReactComponent as Icon } from "../images/Spinner.svg";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useMutation, QueryClient, useQueryClient } from "react-query";
import { useRecoilState } from "recoil";
import {
	currentPoolName as currentPoolNameAtom,
	selectedUser as selectedUserAtom,
	currentAccount as currentAccountAtom,
	submitting as submittingAtom,
	deleteUserModal as deleteUserModalAtom,
	currentPool as currentPoolAtom,
	auth as authAtom,
} from "./Atoms.js";
import axios from "axios";
import { toast } from "react-toastify";

const DeleteUser = () => {
	const queryClient = useQueryClient();
	const [selectedUser, setSelectedUser] = useRecoilState(selectedUserAtom);
	const [currentPoolName, setCurrentPoolName] = useRecoilState(
		currentPoolNameAtom
	);
	const [account, setAccount] = useRecoilState(currentAccountAtom);
	const [open, setOpen] = useRecoilState(deleteUserModalAtom);
	const [pool, setPool] = useRecoilState(currentPoolAtom);
	const [email, setEmail] = useState("");
	const [submitting, setSubmitting] = useRecoilState(submittingAtom);
	const [auth, setAuth] = useRecoilState(authAtom);

	const handleAddClose = () => {
		setOpen(false);
	};
	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	};

	const handleSubmit = async () => {
		setSubmitting(true);
		const body = {
			email: selectedUser,
			pool: pool,
			account: account,
		};
		const response = await axios.post(
			`${process.env.REACT_APP_API}/delete`,
			body,
			{
				headers: {
					Authorization: auth,
				},
			}
		);
		if (response.status === 200) {
			const sqlResponse = await axios.post(
				`${process.env.REACT_APP_API}/deleteDB`,
				body,
				{
					headers: {
						Authorization: auth,
					},
				}
			);
			if (sqlResponse.status === 200) {
				setOpen(false);
				console.log(response);
				setSubmitting(false);
			}
		};
	};

	const mutate = useMutation(handleSubmit, {
		onSuccess: () => {
			toast.success(`${selectedUser} successfully deleted.`);
			queryClient.invalidateQueries(pool);
		},
	});

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleAddClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Delete User</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete {selectedUser} from{" "}
						{currentPoolName}?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleAddClose} color="primary">
						Cancel
					</Button>
					<Button onClick={mutate.mutate} color="primary">
						{submitting ? <Icon /> : "Confirm"}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default DeleteUser;
