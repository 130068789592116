import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useRecoilState } from "recoil";
import {
	currentAccount as currentAccountAtom,
	currentPool as currentPoolAtom,
	resetPasswordModal as resetPasswordModalAtom,
	selectedUser as selectedUserAtom,
	auth as authAtom,
} from "./Atoms";
import axios from "axios";

const Reset = () => {
	const [open, setOpen] = useRecoilState(resetPasswordModalAtom);
	const [account, setAccount] = useRecoilState(currentAccountAtom);
	const [selectedUser, setSelectedUser] = useRecoilState(selectedUserAtom);
	const [currentPool, setCurrentPool] = useRecoilState(currentPoolAtom);
	const [auth, setAuth] = useRecoilState(authAtom);
	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const handleAddClose = () => {
		setOpen(false);
	};

	const handleSubmit = async () => {
		const body = {
			email: selectedUser
			// account: account,
		};
		await axios
			.post(`${process.env.REACT_APP_API}/reset`, body, {
				headers: {
					Authorization: auth,
				},
			})
			.then((res) => {
				setOpen(false);
				console.log(res);
			});
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleAddClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Reset password for {selectedUser}?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleAddClose} color="primary">
						Cancel
					</Button>
					<Button onClick={handleSubmit} color="primary">
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default Reset;
