import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { ReactComponent as Icon } from "../images/Spinner.svg";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useMutation, QueryClient, useQueryClient } from "react-query";
import { useRecoilState } from "recoil";
import {
	submitting as submittingAtom,
	editDisplayNameModal as editDisplayNameModalAtom,
	currentPool as currentPoolAtom,
	currentAccount as currentAccountAtom,
    auth as authAtom,
    selectedDepartmentEdit as selectedDepartmentEditAtom,
    departmentList as departmentListAtom,
    selectedReport as selectedReportAtom
} from "./Atoms.js";
import axios from "axios";
import { toast } from "react-toastify";
import { Checkbox, Label } from 'semantic-ui-react'
import { label } from "aws-amplify";

const EditDisplayName = ({exclusions, departments}) => {
	const queryClient = useQueryClient();

    const [open, setOpen] = useRecoilState(editDisplayNameModalAtom);
    const [auth, setAuth] = useRecoilState(authAtom);
    const [groups, setGroups] = useState([]);
	const [selectedDepartmentEdit, setSelectedDepartmentEdit] = useRecoilState(selectedDepartmentEditAtom);
    const [selectedReport, setSelectedReport] = useRecoilState(selectedReportAtom);
    const [submitting, setSubmitting] = useRecoilState(submittingAtom);
    const [displayName, setDisplayName] = useState("");

	const handleAddClose = () => {
		setOpen(false);
	};

    const handleChange = (e) => {
        setDisplayName(e.target.value);
    }

	const notify = () =>
		toast(` successfully added.`, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});

	const handleSubmit = async () => {
        setSubmitting(true);
        let body = {
            report: selectedReport,
            display_name: displayName
        }
        const response = await axios.post(
            `${process.env.REACT_APP_API}/editdisplayname`,
            body,
			{
				headers: {
					Authorization: auth,
				},
			}
		);
		console.log(response);
		setOpen(false);
		if (response.data === "Success") {
			toast.success(`Success.`);
		} else {
			toast.error(response.data.message);
		}
        setSubmitting(false);
        return response;
	};

	const mutate = useMutation(handleSubmit, {
		onSuccess: () => {
            console.log("success")
			queryClient.invalidateQueries("dashboards");
		},
    });
    
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleAddClose}
				aria-labelledby="form-dialog-title"
				maxWidth="sm"
				// fullWidth={true}
			>
				<DialogTitle id="form-dialog-title">Edit Display Name</DialogTitle>
				<DialogContent>
                    <TextField onChange={handleChange} value={displayName} id="standard-basic" variant="standard"/>
				</DialogContent>
				<DialogActions>
					{submitting ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								width: "50vw",
							}}
						>
							<Icon style={{ width: "80px" }} />
						</div>
					) : (
						<>
							<Button onClick={handleAddClose} color="primary">
								Cancel
							</Button>
							<Button onClick={mutate.mutate} color="primary">
								Save
							</Button>
						</>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default EditDisplayName;
