import React, { useState, useEffect } from "react";
import Login from "./Components/Login/Login";
import Amplify from "aws-amplify";
import Workspace from "./Components/Workspace";
import { auth as authAtom } from "./Components/Atoms";
import { useRecoilState } from "recoil";
import { Auth } from "aws-amplify";
import { QueryClientProvider, QueryClient } from "react-query";
import "./App.css";

Amplify.configure({
	Auth: {
		// REQUIRED - Amazon Cognito Region
		region: "eu-west-1",

		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: process.env.REACT_APP_USER_POOL,

		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: process.env.REACT_APP_CLIENT,

		// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
		mandatorySignIn: false,

		// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
		authenticationFlowType: "USER_PASSWORD_AUTH",
	},
});

const queryClient = new QueryClient();

function App() {
	const [authenticated, setAuthenticated] = useState(false);
	const [auth, setAuth] = useRecoilState(authAtom);
	const [id, setID] = useState();

	useEffect(() => {
		onLoad();
	}, []);

	async function onLoad() {
		try {
			// await Auth.currentSession().then((data) => console.log(data));
			const user = await Auth.currentAuthenticatedUser();
			const idToken = user?.signInUserSession?.idToken?.jwtToken;
			setAuth(idToken);
			setAuthenticated(true);
			setID(idToken);

		} catch (e) {
			if (e !== "No current user") {
				console.log(e);
			}
		}
	}

	return (
		<QueryClientProvider client={queryClient}>
			<div className="App">
				{!authenticated ? (
					<Login
						authenticated={authenticated}
						setAuthenticated={setAuthenticated}
					/>
				) : (
					<>
						<Workspace
						id={id}
							authenticated={authenticated}
							setAuthenticated={setAuthenticated}
						/>
					</>
				)}
			</div>
		</QueryClientProvider>
	);
}

export default App;
