import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import axios from "axios";
import Navbar from "./Navbar";
import AddUser from "./AddUser";
import Reset from "./Reset";
import Set from "./Set";
import {
	auth as authAtom,
	currentScreen as currentScreenAtom,
	selectedUser as selectedUserAtom,
	currentAccount as currentAccountAtom,
	accounts as accountsAtom,
} from "./Atoms";
// import { useQuery } from "react-query";
import UserPool from "./UserPool";
// import { ReactComponent as Icon } from "../images/Spinner.svg";
// import Toolbar from "./Toolbar";
import DeleteUser from "./DeleteUser";
// import Accounts from "./Accounts";
// import Disable from "./Disable";
// import AddAccount from "./AddAccount";
// import EditAccount from "./EditAccount";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Drawer from "./Drawer";
import Divider from "@material-ui/core/Divider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@material-ui/core/Box";
import Departments from "./Departments";
import Reports from "./Reports";
import { Button } from "@material-ui/core";
import EditUser from "./EditUser";
import AssignReport from "./AssignReport";
// import Budgets from "./Budgets";

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<div>{children}</div>
				</Box>
			)}
		</div>
	);
}

const Workspace = ({ authenticated, setAuthenticated, id }) => {
	const [value, setValue] = React.useState(0);
	const [currentScreen, setCurrentScreen] = useRecoilState(currentScreenAtom);
	const [selectedUser, setSelectedUser] = useRecoilState(selectedUserAtom);
	const [currentAccount, setCurrentAccount] =
		useRecoilState(currentAccountAtom);
	const [userPools, setUserPools] = useState([]);
	const [accounts, setAccounts] = useRecoilState(accountsAtom);
	const [auth, setAuth] = useRecoilState(authAtom);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	async function getBrands() {
		const response = await axios.get(`${process.env.REACT_APP_API}/brands`);
		return response.data;
	}
	// const brands = useQuery(`brands`, getBrands);

	const style = {
		height: "100vh",
		width: "100vw",
		overflowY: "scroll",
	};

	return (
		<div style={style}>
			<Navbar
				authenticated={authenticated}
				setAuthenticated={setAuthenticated}
			/>
			<Divider light />
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
				}}
			>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="simple tabs example"
				>
					<Tab label="Users" {...a11yProps(0)} />
					<Tab label="Departments" {...a11yProps(1)} />
					<Tab label="Reports" {...a11yProps(2)} />
				</Tabs>
			</div>
			<TabPanel value={value} index={0}>
				<div style={{ margin: "0 50px 0 50px" }}>
					<UserPool idToken={id} id={process.env.REACT_APP_USER_POOL} />
				</div>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<div style={{ width: "100%" }}>
					<Departments />
				</div>
			</TabPanel>
			<TabPanel value={value} index={2}>
				<div>
					<Reports />
				</div>
			</TabPanel>

			<AssignReport />
			<DeleteUser />
			<AddUser />
			<Reset />
			{/* <Disable /> */}
			<Drawer />
			<Set />
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</div>
	);
};

export default Workspace;
