import React, { useEffect } from "react";
import "./Navbar.css";
import { useRecoilState } from "recoil";
import {
	authenticated as authenticatedAtom,
	drawerOpen as drawerOpenAtom,
	addUserModal as addUserModalAtom,
	resetPasswordModal as resetPasswordModalAtom,
	currentScreen as currentScreenAtom,
} from "./Atoms.js";
import Logo from "../images/Logo.jpg";
import { Auth } from "aws-amplify";

const Navbar = ({ setAuthenticated }) => {
	const [currentScreen, setCurrentScreen] = useRecoilState(currentScreenAtom);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [drawerOpen, setDrawerOpen] = useRecoilState(drawerOpenAtom);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		// setAnchorEl(event.currentTarget);
		setDrawerOpen(true);
	};

	async function handleLogout() {
		try {
			await Auth.signOut();
			setAuthenticated(false);
		} catch (error) {
			console.log("error signing out: ", error);
		}
	}

	return (
		<div style={{ backgroundColor: "black" }}>
			<div className="navbar">
				<div>
					<img src={Logo} alt="logo" style={{ height: "10vh" }} />
				</div>
				<div className="search-sign-out">
					<div onClick={handleLogout} className="sign-out-button">
						<h3 className="sign-out">Log Out</h3>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Navbar;
