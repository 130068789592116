import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { ReactComponent as Icon } from "../images/Spinner.svg";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useMutation, QueryClient, useQueryClient } from "react-query";
import { useRecoilState } from "recoil";
import {
	submitting as submittingAtom,
	assignReportModal as assignReportModalAtom,
	currentPool as currentPoolAtom,
	currentAccount as currentAccountAtom,
	auth as authAtom,
	selectedDepartmentEdit as selectedDepartmentEditAtom,
	departmentList as departmentListAtom,
	selectedReport as selectedReportAtom,
	dashb,
} from "./Atoms.js";
import axios from "axios";
import { toast } from "react-toastify";
import { Checkbox, Label } from "semantic-ui-react";
import { label } from "aws-amplify";
import useDepartmentList from "../Hooks/useDepartmentList";

const AssignReport = ({ dashboards }) => {
	const queryClient = useQueryClient();

	const [assignOpen, setAssignOpen] = useRecoilState(assignReportModalAtom);
	const [groups, setGroups] = useState([]);
	const [selectedDepartmentEdit, setSelectedDepartmentEdit] = useRecoilState(
		selectedDepartmentEditAtom
	);
	const [selectedReport, setSelectedReport] = useRecoilState(
		selectedReportAtom
	);
	const [submitting, setSubmitting] = useRecoilState(submittingAtom);
	const [auth, setAuth] = useRecoilState(authAtom);
	const [list, setList] = useState([]);
	const [assigned, setAssigned] = useState([]);
	const [dashboardType, setDashboardType] = useState("");
	const [included, setIncluded] = useState([]);
	const [excluded, setExcluded] = useState([]);
	const glist = useDepartmentList();
	useEffect(() => {
		console.log(glist.data);
	}, [glist]);

	const departmentList = useDepartmentList();

	const handleAddClose = () => {
		setAssignOpen(false);
	};

	useEffect(() => {
		let list = dashboards
			?.filter((a) => {
				return a.dashboard_name === selectedReport;
			})
			.map((dep) => {
				return dep.department_name;
			});
		const type = dashboards?.filter((a) => {
			return a.dashboard_name === selectedReport;
		})[0].dashboard_type;
		setDashboardType(type);
		setAssigned(list);
	}, [selectedReport]);

	const handleReport = (event, data) => {
		console.log(data.value);
		let a = [...assigned];
		if (a.includes(data.value)) {
			let index = a.indexOf(data.value);
			a.splice(index, 1);
			setAssigned(a);
		} else {
			a.push(data.value);
			setAssigned(a);
		}
	};

	const handleSubmit = async () => {
		if (assigned.includes("Unassigned")) {
			let index = assigned.indexOf("Unassigned");
			assigned.splice(index, 1);
		}
		const body = {
			report: selectedReport,
			departments: assigned,
			dashboard_type: dashboardType,
		};
		console.log(body);
		setSubmitting(true);
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API}/assignreport`,
				body,
				{
					headers: {
						Authorization: auth,
					},
				}
			);
			console.log(response);
			setAssignOpen(false);
			if (response.data === "Success") {
				toast.success(`Success.`);
			} else {
				toast.error(response.data.message);
			}
			setSubmitting(false);
			return response;
		} catch (error) {
			toast.error(error);
		}
	};

	const assignMutate = useMutation(handleSubmit, {
		onSuccess: () => {
			console.log("success");
			queryClient.invalidateQueries("dashboards");
		},
	});

	function handleChecked(group) {
		if (assigned?.includes(group)) {
			return true;
		}
	}

	return (
		<div>
			<Dialog
				open={assignOpen}
				onClose={handleAddClose}
				aria-labelledby="form-dialog-title"
				maxWidth="sm"
				fullWidth={true}
			>
				<DialogTitle id="form-dialog-title">{`Assign Report: ${selectedReport}`}</DialogTitle>
				<DialogContent>
					<div style={{ display: "flex", flexDirection: "column" }}>
						{departmentList.status === "success" &&
							departmentList.data.map((user, index) => {
								return (
									<Checkbox
										style={{ marginTop: "10px" }}
										checked={handleChecked(user.department_name)}
										label={user.department_name}
										value={user.department_name}
										key={index}
										onChange={handleReport}
									/>
								);
							})}
					</div>
				</DialogContent>
				<DialogActions>
					{submitting ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								width: "50vw",
							}}
						>
							<Icon style={{ width: "80px" }} />
						</div>
					) : (
						<>
							<Button onClick={handleAddClose} color="primary">
								Cancel
							</Button>
							<Button onClick={assignMutate.mutate} color="primary">
								save
							</Button>
						</>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default AssignReport;
