import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import "./Drawer.css";
import { drawerOpen as drawerOpenAtom, currentScreen as currentScreenAtom, authenticated as authenticatedAtom } from "./Atoms";
import { useRecoilState } from "recoil";

const useStyles = makeStyles({
    list: {
        width: 250,
        backgroundColor: "black",
        color: "white",
        height: "100%"
    },
    fullList: {
        width: 'auto',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        // necessary for content to be below app bar
        justifyContent: 'flex-end',
        backgroundColor: "#efb758"
    },
});

export default function TemporaryDrawer() {
    const classes = useStyles();


    const [open, setOpen] = useRecoilState(drawerOpenAtom);
    const [currentScreen, setCurrentScreen] = useRecoilState(currentScreenAtom);
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(false);
    };

    const handleClose = e => {
        if (e.currentTarget.innerText === "Accounts") {
            setCurrentScreen("manage_accounts");
        }

        else if (e.currentTarget.innerText === "User Pools") {
            setCurrentScreen("manage_pools")
        }

    }

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <div className={classes.drawerHeader}>
                <IconButton onClick={toggleDrawer(false)}>
                    <ChevronLeftIcon style={{ color: "white" }} />
                </IconButton>
            </div>
            <List>
                <ListItem onClick={handleClose} button key={"User Pools"}>
                    <ListItemText className="list_item" primary={"User Pools"} />
                </ListItem>
                <ListItem onClick={handleClose} button key={"Accounts"}>
                    <ListItemText className="list_item" primary={"Accounts"} />
                </ListItem>
            </List>
            <Divider style={{ backgroundColor: "white" }} />
        </div>
    );

    return (
        <div>
            <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
                {list("left")}
            </Drawer>
        </div>
    );
}