import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useRecoilState } from "recoil";
import {
	currentAccount as currentAccountAtom,
	currentPool as currentPoolAtom,
	setPasswordModal as setPasswordModalAtom,
	selectedUser as selectedUserAtom,
	auth as authAtom,
} from "./Atoms";
import axios from "axios";
import { useMutation, QueryClient, useQueryClient } from "react-query";
import { toast } from "react-toastify";

const Reset = () => {
	const queryClient = useQueryClient();
	const [open, setOpen] = useRecoilState(setPasswordModalAtom);
	const [account, setAccount] = useRecoilState(currentAccountAtom);
	const [selectedUser, setSelectedUser] = useRecoilState(selectedUserAtom);
	const [currentPool, setCurrentPool] = useRecoilState(currentPoolAtom);
	const [auth, setAuth] = useRecoilState(authAtom);
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const handleAddClose = () => {
		setOpen(false);
	};

	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
	};
	const handleConfirmPasswordChange = (e) => {
		setConfirmPassword(e.target.value);
	};

	const handleSubmit = async () => {
		if (password !== confirmPassword) {
			alert("Passwords do not match.");
			return;
		}
		const body = {
			email: selectedUser,
			password: password,
		};
		console.log(body);
		const response = await axios.post(
			`${process.env.REACT_APP_API}/set`,
			body,
			{
				headers: {
					Authorization: auth,
				},
			}
		);
		if (response.data.message === undefined) {
			toast.success(`Success.`);
			setOpen(false);
			console.log(response);
		} else {
			console.log(response)
			toast(response.data.message);
		}
	};

	const mutate = useMutation(handleSubmit, {
		onSuccess: () => {
			setPassword("");
			setConfirmPassword("");
			queryClient.invalidateQueries(currentPool);
		},
	});

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleAddClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Set password for {selectedUser}?
					</DialogContentText>
					<TextField
						onChange={handlePasswordChange}
						value={password}
						autoFocus
						margin="dense"
						id="password"
						label="Password"
						type="password"
						fullWidth
					/>
					<TextField
						onChange={handleConfirmPasswordChange}
						value={confirmPassword}
						margin="dense"
						id="confirmPassword"
						label="Confirm Password"
						type="password"
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleAddClose} color="primary">
						Cancel
					</Button>
					{/* <Button onClick={mutate} color="primary">
						Confirm
					</Button> */}
					<Button onClick={mutate.mutate} color="primary">Confirm</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default Reset;
