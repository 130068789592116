import axios from 'axios';
import React, {useState, useEffect} from 'react'
import { useQuery, useMutation, useQueryClient  } from "react-query";
import { Auth } from "aws-amplify";
import ReportList from './ReportList';
import EditReport from './EditReport';
import useUsers from "../Hooks/useUsers"
import { Button } from "@material-ui/core";
import { ReactComponent as Spinner } from "../images/Spinner.svg";
import AssignReport from './AssignReport';
import differenceBy from 'lodash/differenceBy'
import EditDisplayName from './EditDisplayName';

const Reports = () => {

const queryClient = useQueryClient()
const [loading, setLoading] = useState(true);

async function getReports() {
    const user = await Auth.currentAuthenticatedUser();
    const idToken = user.signInUserSession.idToken.jwtToken;
    const data = await axios.get(
        `${process.env.REACT_APP_API}/dashboards`,
        {
            headers: {
                Authorization: idToken,
            },
        }
    );
    let diff = differenceBy(main.data,data.data, 'dashboard_name');
    if (diff.length > 0) {
        let final = diff.map(d => {
            return {
                dashboard_name: d.dashboard_name,
                department_name: "Unassigned",
                created: d.created,
                last_updated: d.last_updated,
                dashboard_type: d.dashboard_type
            }
        });
        console.log(data.data);
        let f = [...data.data, ...final];
        console.log(f);
        setLoading(false);
        return f;
    }
    else {
        return data.data;
    }
    return data.data;
}

async function getDepartments() {
    const user = await Auth.currentAuthenticatedUser();
    const idToken = user.signInUserSession.idToken.jwtToken;
    const data = await axios.get(
        `${process.env.REACT_APP_API}/departments`,
        {
            headers: {
                Authorization: idToken,
            },
        }
    );
    console.log(data.data)
    return data.data;
}



async function getMain() {
    const user = await Auth.currentAuthenticatedUser();
    const idToken = user.signInUserSession.idToken.jwtToken;
    const data = await axios.get(
        `${process.env.REACT_APP_API}/dashboardsmain`,
        {
            headers: {
                Authorization: idToken,
            },
        }
    );
    console.log(data.data);
    return data.data;
}

async function getExclusions() {
    const user = await Auth.currentAuthenticatedUser();
    const idToken = user.signInUserSession.idToken.jwtToken;
    const data = await axios.get(
        `${process.env.REACT_APP_API}/exclusions`,
        {
            headers: {
                Authorization: idToken,
            },
        }
    );
    console.log(data.data);
    return data.data;
}

const users = useUsers();

const main = useQuery("main", getMain);

const dep = useQuery("departments", getDepartments)

const exclusions = useQuery("exclusions", getExclusions);

const dashboards = useQuery("dashboards", getReports, {
    enabled: !!main?.data
});

async function handleSync() {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const idToken = user.signInUserSession.idToken.jwtToken;
    const data = await axios.get(
        `${process.env.REACT_APP_API}/syncReports`,
        {
            headers: {
                Authorization: idToken,
            },
        }
    );
    return data.data;
}

const mutation = useMutation(handleSync, {
    onSuccess: () => {
        console.log("Success")
        queryClient.invalidateQueries("dashboards");
        queryClient.invalidateQueries("main");
        setLoading(false);
    },
})


    return (
        <>
            {loading? <Spinner /> : <> 
            {/* <Button onClick={mutation.mutate} color="primary" variant="outlined">Sync</Button> */}
            <EditReport exclusions={exclusions.data} users={users.data} departments={dep.data}/>
            <AssignReport dashboards={dashboards.data}/>
            <EditDisplayName />
            <ReportList dashboards={dashboards.data} /> </>}
        </>
    )
}

export default Reports;