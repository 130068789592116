import React, { useState } from "react";
import { useQuery } from "react-query";
import { Auth } from "aws-amplify";
import axios from "axios";

const useDepartmentList = () => {
	async function getDepartments() {
		const user = await Auth.currentAuthenticatedUser();
		const idToken = user.signInUserSession.idToken.jwtToken;
		const data = await axios.get(
			`${process.env.REACT_APP_API}/departmentlist`,
			{
				headers: {
					Authorization: idToken,
				},
			}
		);
		return data.data;
	}
	const { status, isLoading, error, data } = useQuery(
		"departmentList",
		getDepartments
	);
	return { status, isLoading, error, data };
};

export default useDepartmentList;
