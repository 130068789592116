import React, { useState } from "react";
import { useQuery } from "react-query";
import { Auth } from "aws-amplify";
import axios from "axios";

const useUsers = () => {
	async function getUsers() {
		const user = await Auth.currentAuthenticatedUser();
		const idToken = user.signInUserSession.idToken.jwtToken;
		const data = await axios.get(`${process.env.REACT_APP_API}/users`, {
			headers: {
				Authorization: idToken,
			},
		});
		return data.data;
	}
	const { status, isLoading, error, data } = useQuery("users", getUsers);
	return { status, isLoading, error, data };
};

export default useUsers;
