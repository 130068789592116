import React, { useState, useEffect } from "react";
import MaterialTable, { MTableGroupbar, MTableEditCell } from "material-table";
import { useRecoilState } from "recoil";
import {
	setPasswordModal as setPasswordModalAtom,
	disableUserModal as disableUserModalAtom,
	deleteUserModal as deleteUserModalAtom,
	currentPoolName as currentPoolNameAtom,
	currentPool as currentPoolAtom,
	addUserModal as addUserModalAtom,
	resetPasswordModal as resetPasswordModalAtom,
	selectedUser as selectedUserAtom,
	editReportModal as editReportModalAtom,
	selectedDepartmentEdit as selectedDepartmentEditAtom,
	selectedReport as selectedReportAtom,
	assignReportModal as assignReportModalAtom,
	editDisplayNameModal as editDisplayNameModalAtom
} from "./Atoms.js";
import Button from "@material-ui/core/Button";

const ReportList = ({dashboards}) => {

	const [editReportModal, setEditReportModal] = useRecoilState(editReportModalAtom);
	const [selectedDepartmentEdit, setSelectedDepartmentEdit] = useRecoilState(selectedDepartmentEditAtom);
	const [selectedReport, setSelectedReport] = useRecoilState(selectedReportAtom);
	const [assignReportModal, setAssignReportModal] = useRecoilState(assignReportModalAtom);
	const [editDisplayNameModal, setEditDisplayNameModal] = useRecoilState(editDisplayNameModalAtom);
	const tableStyle = {
		// display: "flex",
		// flexDirection: "column",
		// justifyContent: "center",
		// alignItems: "center",
		// backgroundColor: "yellow"
	};

	const style = {
		textAlign: "left",
		fontFamily: '"Montserrat", sans-serif',
	};

	const [state, setState] = useState({
		columns: [
			{
				title: "Dashboard",
				field: "dashboard_name",
                cellStyle: style,
                headerStyle: {
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "flex-start"
				},
                cellStyle: {
					textAlign: "left",
				},
			},
			{
				title: "Department",
				field: "department_name",
                defaultGroupOrder: 0
			},
			{
				title: "Display Name",
				field: "display_name"
			},
			{
				title: "Created",
				field: "created"
			},
			{
				title: "Last Updated",
				field: "last_updated"
			},
		],
	});

	return (
		<div style={tableStyle}>
			<div>
				<MaterialTable
					title={`Reports`}
					columns={state.columns}
					data={dashboards}
					// celleditable={{
					// 	onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
					// 	  return new Promise((resolve, reject) => {
					// 		console.log('newValue: ' + newValue);
					// 		// setTimeout(resolve, 1000);
					// 	  });
					// 	}
					//   }}
                    style={tableStyle}
                    actions={[
                        {
							icon: "block",
							tooltip: "Exclude",
							onClick: (event, rowData) => {
								setSelectedDepartmentEdit(rowData.department_name);
								setSelectedReport(rowData.dashboard_name);
								setEditReportModal(true);
							},
						},
                        {
							icon: "edit",
							tooltip: "Edit",
							onClick: (event, rowData) => {
								setAssignReportModal(true);
								setSelectedReport(rowData.dashboard_name);
							},
						},
                        {
							icon: "edit",
							tooltip: "Edit Display Name",
							onClick: (event, rowData) => {
								setEditDisplayNameModal(true);
								setSelectedReport(rowData.dashboard_name);
							},
						},
                    ]}
					components={{
						Groupbar: (props) => (
							<div style={{height: "0px"}}>
								<MTableGroupbar
									style={{ display: "none" }}
									{...props}
								/>
							</div>
						),
					}}
					options={{
						toolbar: false,
                        search: true,
                        grouping: false,
						filtering: false,
						paging: false,
						actionsColumnIndex: 8,
						headerStyle: {
							textAlign: "left",
						},
					}}
				/>
			</div>
		</div>
	);
};

export default ReportList;
