import { atom } from "recoil";
import axios from "axios";

export const auth = atom({
	key: "auth",
	default: "",
});

export const accounts = atom({
	key: "accounts",
	default: [],
});

export const currentAccount = atom({
	key: "currentAccount",
	default: "capeunionmart",
});

export const userPools = atom({
	key: "userPools",
	default: [],
});

export const authenticated = atom({
	key: "authenticated",
	default: false,
});

export const currentScreen = atom({
	key: "currentScreen",
	default: "manage_pools",
});

export const drawerOpen = atom({
	key: "drawerOpen",
	default: false,
});

export const addUserModal = atom({
	key: "addUserModal",
	default: false,
});

export const editUserModal = atom({
	key: "editUserModal",
	default: false,
});

export const editReportModal = atom({
	key: "editReportModal",
	default: false,
});

export const assignReportModal = atom({
	key: "assignReportModal",
	default: false,
});

export const addAccountModal = atom({
	key: "addAccountModal",
	default: false,
});

export const editAccountModal = atom({
	key: "editAccountModal",
	default: false,
});

export const editDisplayNameModal = atom({
	key: "editDisplayNameModal",
	default: false,
});

export const editBrandModal = atom({
	key: "editBrandModal",
	default: false,
});

export const poolInfoModal = atom({
	key: "poolInfoModal",
	default: false,
});

export const deleteUserModal = atom({
	key: "deleteUserModal",
	default: false,
});

export const resetPasswordModal = atom({
	key: "resetPasswordModal",
	default: false,
});

export const setPasswordModal = atom({
	key: "setPasswordModal",
	default: false,
});

export const disableUserModal = atom({
	key: "disableUserModal",
	default: false,
});

export const selectedUser = atom({
	key: "selectedUser",
	default: "",
});

export const currentPool = atom({
	key: "currentPool",
	default: "eu-west-1_sxHCFDQv5",
});

export const currentPoolName = atom({
	key: "currentPoolName",
	default: "",
});

export const submitting = atom({
	key: "submitting",
	default: false,
});

export const selectedAccount = atom({
	key: "selectedAccount",
	default: "",
});

export const selectedDepartmentEdit = atom({
	key: "selectedDepartmentEdit",
	default: "",
});

export const selectedReport = atom({
	key: "selectedReport",
	default: "",
});

export const departmentList = atom({
	key: "departmentList",
	default: [],
});
