import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { ReactComponent as Icon } from "../images/Spinner.svg";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useMutation, QueryClient, useQueryClient } from "react-query";
import { useRecoilState } from "recoil";
import {
	submitting as submittingAtom,
	editUserModal as editUserModalAtom,
	currentPool as currentPoolAtom,
	currentAccount as currentAccountAtom,
	auth as authAtom,
	selectedDepartmentEdit as selectedDepartmentEditAtom,
	// departmentList as departmentListAtom,
} from "./Atoms.js";
import axios from "axios";
import { toast } from "react-toastify";
import { Checkbox, Label } from "semantic-ui-react";
import useDepartmentList from "../Hooks/useDepartmentList";

const EditUser = ({ departments }) => {
	const queryClient = useQueryClient();

	const [open, setOpen] = useRecoilState(editUserModalAtom);
	const [groups, setGroups] = useState([]);
	const [selectedDepartmentEdit, setSelectedDepartmentEdit] = useRecoilState(
		selectedDepartmentEditAtom
	);
	const [submitting, setSubmitting] = useRecoilState(submittingAtom);
	const [auth, setAuth] = useRecoilState(authAtom);
	// const [departmentList, setDepartmentList] = useRecoilState(
	// 	departmentListAtom
	// );
	const [included, setIncluded] = useState([]);
	const [selectAll, setSelectAll] = useState(false);

	useEffect(() => {
		console.log(selectedDepartmentEdit);
		let list = departments
			?.filter((department) => {
				return department.email === selectedDepartmentEdit;
			})
			.map((dep) => dep.department_name);
		if (list.includes("Unassigned")) {
			let index = list.indexOf("Unassigned");
			list.splice(index, 1);
		}
		setIncluded(list);
		console.log(list);
	}, [selectedDepartmentEdit]);

	// useEffect(() => {
	// 	console.log(departmentList);
	// }, [departmentList])

	// const groupList = [
	// 	"HR",
	// 	"Finance",
	// 	"Merchandise",
	// 	"Retail Ops",
	// 	"Web",
	// 	"Logistics",
	// 	"Uploads",
	// 	"Internal",
	// ];

	const departmentList = useDepartmentList();

	const handleAddClose = () => {
		setOpen(false);
	};

	const handleDepartment = (event, data) => {
		let departments = [...included];
		if (departments.includes(data.value)) {
			let index = departments.indexOf(data.value);
			departments.splice(index, 1);
			setIncluded(departments);
		} else {
			departments.push(data.value);
			setIncluded(departments);
		}
	};

	const notify = () =>
		toast(` successfully added.`, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});

	const handleSubmit = async () => {
		console.log(included);
		const body = {
			email: selectedDepartmentEdit,
			departments: included,
		};
		setSubmitting(true);
		const response = await axios.post(
			`${process.env.REACT_APP_API}/edit`,
			body,
			{
				headers: {
					Authorization: auth,
				},
			}
		);
		console.log(response);
		setOpen(false);
		if (response.data === "Success") {
			toast.success(`Success.`);
		} else {
			toast.error(response.data.message);
		}
		setSubmitting(false);
		return response;
	};

	const mutate = useMutation(handleSubmit, {
		onSuccess: () => {
			console.log("success");
			queryClient.invalidateQueries("departments");
		},
	});

	function handleChecked(group) {
		if (included.includes(group)) {
			return true;
		}
	}

	// function handleSelectAll() {
	// 	if (selectAll) {
	// 		setSelectAll(!selectAll);
	// 		setIncluded([]);
	// 	} else {
	// 		setSelectAll(!selectAll);
	// 		setIncluded(groupList);
	// 	}
	// }

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleAddClose}
				aria-labelledby="form-dialog-title"
				maxWidth="sm"
				fullWidth={true}
			>
				<DialogTitle id="form-dialog-title">Edit Departments</DialogTitle>
				<DialogContent style={{ minHeight: "30vh" }}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							height: "10vh",
							justifyContent: "space-evenly",
							marginTop: "1vh",
						}}
					>
						<Label style={{ marginTop: "50px" }} pointing="below">
							Select Department(s)
						</Label>
						{/* <Checkbox checked={selectAll? true:false} label={"Select All"} value={selectAll} onChange={handleSelectAll}/> */}
						{departmentList.data.map((group, index) => {
							return (
								<Checkbox
									checked={handleChecked(group.department_name)}
									label={group.department_name}
									value={group.department_name}
									key={index}
									onChange={handleDepartment}
								/>
							);
						})}
					</div>
				</DialogContent>
				<DialogActions>
					{submitting ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								width: "50vw",
							}}
						>
							<Icon style={{ width: "80px" }} />
						</div>
					) : (
						<>
							<Button onClick={handleAddClose} color="primary">
								Cancel
							</Button>
							<Button onClick={mutate.mutate} color="primary">
								Confirm
							</Button>
						</>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default EditUser;
